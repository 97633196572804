var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('settings-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],ref:"erDataTables",staticClass:"pm-setting-table-main-container is-filters-set-absolute",attrs:{"element-loading-background":"white","tableData":_vm.tableData,"columns":_vm.columnsObject,"tableProps":_vm.elTableProps,"addPadingToSearch":true,"total":_vm.total,"button":false,"download":true,"columnselector_require":false,"selectedColumnHeadersSortable":false,"searchProperty":"device_code","placeHolderForSearch":"Search_device_code"},on:{"downloadExcel":_vm.handleDownloadExcel,"cell-click":_vm.handleCellClick,"reload":_vm.handleLoadData,"loadData":_vm.handleLoadData,"cleared":_vm.handleLoadData},scopedSlots:_vm._u([{key:"device_ble_last_communication_time",fn:function(ref){
var row = ref.row;
return [_vm._v("\n  "+_vm._s(_vm.ftm__formatEmpty(
        row.data.ble_comm_stats !== undefined ?  _vm.adm__filterToFormatDate(row.data.ble_comm_stats.utc_last_communicated_at, "dd-MMM-yyyy HH:mm") : "--"
      ))+"\n  ")]}},{key:"device_af_control_updated_time",fn:function(ref){
      var row = ref.row;
return [_vm._v("\n  "+_vm._s(_vm.ftm__formatEmpty(
        row.data.ble_comm_stats !== undefined ? _vm.adm__filterToFormatDate(row.data.ble_comm_stats.utc_last_communicated_at, "dd-MMM-yyyy HH:mm") : "--"
      ))+"\n  ")]}},{key:"device_ble_chip_type",fn:function(ref){
      var row = ref.row;
return [_vm._v("\n  "+_vm._s(_vm.getBLEChipType(row.data.settings.ble_chip_type))+"\n  ")]}},{key:"gw_ble_chip_type",fn:function(ref){
      var row = ref.row;
return [_vm._v("\n  "+_vm._s(row.data.ble_gateway_data !== undefined ? _vm.getBLEChipType(row.data.ble_gateway_data.settings.ble_chip_type) : '--')+"\n  ")]}},{key:"af_control_source_configuration",fn:function(ref){
      var row = ref.row;
return [_vm._v("\n  "+_vm._s(row.data.ble_gateway_data !== undefined && row.data.ble_gateway_data.settings.af_control_source_config !== undefined ? _vm.getSourceConfiguration(row.data.ble_gateway_data.settings.af_control_source_config) : '--')+"\n  ")]}},{key:"device_current_af_control_source",fn:function(ref){
      var row = ref.row;
return [_vm._v("\n  "+_vm._s(row.data.ble_metrics !== undefined && row.data.ble_metrics.af_primary_controller_status !== undefined ? _vm.getSourceConfiguration(row.data.ble_metrics.af_primary_controller_status.value) : '--')+"\n  ")]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }