<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: gatewayFilters.vue
Description:This file contains the details the customer gateways with column wise filters
-->
<template>
  <div v-loading="loading">
    <div class="item-container is-filters-set-relative">
      <div class="table-filters-right">
        <er-download-drop-down
          :downloadTypes="['EXCEL']"
          @download-action="handleDownloadExcel"
        ></er-download-drop-down>
        <er-column-selector
          :columns="computedColumns"
          keyToStoreDefaults="gateway_filters"
          keyToDecideDefaultCol="isVisible"
          :allow-check="true"
          :title="'Column Visibility'"
          @change-selection="handleChangeColumnSelection"
        ></er-column-selector>
      </div>
    </div>
    <ag-grid-vue
      ref="agGridGatewayFilters"
      style="width: 100%; height: calc(100vh - 120px)"
      class="ag-theme-alpine"
      :gridOptions="gridOptions"
      :columnDefs="columnDefs"
      :pagination="true"
      :frameworkComponents="frameworkComponents"
      :rowData="deviceData"
      @grid-ready="handleGridReady"
    >
    </ag-grid-vue>
  </div>
</template>
<script>
import { AgGridVue } from "ag-grid-vue";
import { mapActions } from "vuex";
import adminDatesHandlerMixin from "@/mixins/adminDatesHandlerMixin";
import agGridStringCompareFilter from "@/components/superadmin/shared/agGridStringCompareFilter";
import agGridLastCommCellRenderer from "@/components/superadmin/shared/agGridLastCommCellRenderer";
import XLSX from "xlsx";
import filtersMixin from "@/mixins/filtersMixin";
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
export default {
  mixins: [adminDatesHandlerMixin, filtersMixin, errorHandlerMixin],
  data() {
    return {
      columnDefs: null,
      rowData: null,
      frameworkComponents: null,
      loading: false,
      deviceDetails: [],
      gridOptions: {},
      gridApi: undefined,
      gridColumnApi: undefined,
    };
  },
  components: {
    AgGridVue,
  },
  computed: {
    computedColumns: function () {
      const columns = this.$lodash.cloneDeep(
        this.columnDefs.reduce((acc, curr) => {
          acc[curr.colId] = curr;
          return acc;
        }, {})
      );
      // Object.keys(columns).forEach(x => {
      //   columns[x].label = this.$t(columns[x].label);
      // });
      Object.keys(columns).forEach((x) => {
        columns[x].label = columns[x].headerName;
      });
      return columns;
    },
    deviceData: function () {
      return this.$lodash.cloneDeep(this.deviceDetails).map((x) => {
        ["v1_parsing", "v2_parsing"].forEach((key) => {
          if (x.settings && x.settings[key]) {
            x.settings[key] = 1;
          } else {
            if (!x.settings) {
              x.settings = {};
            }
            x.settings[key] = 0;
          }
        });
        x.customer_count = x.user_data ? x.user_data.length : 0;
        x.customer_names =
          x.user_data && x.user_data.length > 0
            ? x.user_data.map((user) => user.full_name).join(",")
            : "--";
        x.customer_timezone =
          x.user_data && x.user_data.length > 0
            ? x.user_data[0].timezone?.name
            : "--";
        // x.device_timezone =
        //   x.user_data && x.user_data.length > 0
        //     ? x.user_data.map((user) => user.full_name).join(",")
        //     : "--";
        x.settings.latitude =
          typeof x.settings.latitude !== "undefined"
            ? x.settings.latitude
            : "--";
        x.settings.longitude =
          typeof x.settings.longitude !== "undefined"
            ? x.settings.longitude
            : "--";

        return x;
      });
    },
  },
  async created() {
    await this.initComponent();
  },
  async beforeMount() {
    this.columnDefs = [
      {
        headerName: "Gateway Code",
        isVisible: true,
        colId: "gateway_code",
        field: "code",
        sortable: true,
        filter: true,
      },
      {
        headerName: "Alias",
        isVisible: true,
        colId: "alias",
        field: "title",
        sortable: true,
        filter: true,
      },
      {
        headerName: "V1_id",
        isVisible: true,
        colId: "v1_id",
        field: "v1_id",
        sortable: true,
        filter: true,
      },
      {
        headerName: "V2_id",
        isVisible: true,
        colId: "v2_id",
        field: "_id",
        sortable: true,
        filter: true,
      },
      {
        headerName: "Customer Name",
        isVisible: true,
        colId: "customer_name",
        field: "customer_names",
        sortable: true,
        filter: true,
      },
      {
        headerName: "Customer Timezone",
        isVisible: true,
        colId: "customer_timezone",
        field: "customer_timezone",
        sortable: true,
        filter: true,
      },
      {
        headerName: "Device Timezone",
        isVisible: true,
        colId: "settings.timezone",
        field: "settings.timezone",
        sortable: true,
        filter: true,
      },
      {
        headerName: "Settings Interval",
        isVisible: true,
        colId: "settings_interval",
        field: "settings.settings_interval",
        label: "Settings Interval",
        required: false,
        minWidth: 100,
        filter: true,
      },
      {
        headerName: "Lora Channel",
        isVisible: true,
        colId: "lora_channel",
        field: "settings.lora_channel",
        label: "Lora Channel",
        required: true,
        minWidth: 100,
        filter: true,
      },
      {
        headerName: "Lora Active",
        isVisible: true,
        colId: "lora_active",
        field: "settings.lora_active",
        label: "Lora Active",
        required: true,
        minWidth: 80,
        filter: true,
      },
      {
        headerName: "BLE Active",
        isVisible: true,
        colId: "ble_active",
        field: "settings.ble_active",
        label: "BLE Active",
        required: true,
        minWidth: 80,
        filter: true,
      },
      {
        headerName: "Wifi Active",
        isVisible: true,
        colId: "wifi_active",
        field: "settings.wifi_active",
        label: "Wifi Active",
        required: true,
        minWidth: 80,
        filter: true,
      },
      {
        headerName: "Latitude",
        isVisible: true,
        colId: "latitude",
        field: "settings.latitude",
        label: "Latitude",
        required: true,
        minWidth: 60,
        filter: false,
      },
      {
        headerName: "longitude",
        isVisible: true,
        colId: "longitude",
        field: "settings.longitude",
        label: "Longitude",
        required: true,
        minWidth: 60,
        filter: false,
      },
      {
        headerName: "Wifi Networks",
        isVisible: true,
        colId: "wifi_networks",
        field: "settings.wifi_networks",
        label: "Wifi Networks",
        required: false,
        minWidth: 100,
        filter: true,
      },
      {
        headerName: "Wifi Passwords",
        isVisible: true,
        colId: "wifi_passwords",
        field: "settings.wifi_passwords",
        label: "Wifi Passwords",
        required: false,
        minWidth: 100,
        filter: true,
      },
      {
        headerName: "Wifi Security",
        isVisible: true,
        colId: "wifi_security",
        field: "settings.wifi_security",
        label: "Wifi Security",
        required: false,
        minWidth: 100,
        filter: true,
      },
      {
        headerName: "Country",
        isVisible: true,
        colId: "country",
        field: "settings.country",
        label: "Country",
        required: false,
        minWidth: 100,
        filter: true,
      },
      {
        headerName: "MAC Address",
        isVisible: true,
        colId: "mac_address",
        field: "settings.mac_address",
        label: "MAC Address",
        required: false,
        minWidth: 150,
        filter: true,
      },
      {
        headerName: "OS Version",
        isVisible: true,
        colId: "os_version",
        field: "settings.os_version",
        label: "OS Version",
        required: false,
        minWidth: 100,
        filter: true,
      },
      {
        headerName: "Software Version",
        isVisible: true,
        colId: "software_version",
        field: "settings.software_version",
        sortable: true,
        filter: "agGridStringCompareFilter",
      },
      {
        headerName: "Hardware Version",
        isVisible: true,
        colId: "hardware_version",
        field: "settings.hardware_version",
        sortable: true,
        filter: true,
      },
      {
        headerName: "IP Interface",
        isVisible: true,
        colId: "ip_interface",
        field: "settings.ip_interface",
        label: "IP Interface",
        required: false,
        minWidth: 100,
        filter: true,
      },
      {
        headerName: "IP Address",
        isVisible: true,
        colId: "ip_address",
        field: "settings.ip_address",
        label: "IP Address",
        required: false,
        minWidth: 120,
        filter: true,
      },
      {
        headerName: "V1 Parsing Enabled",
        isVisible: true,
        colId: "v1_parsing",
        field: "settings.v1_parsing",
        sortable: true,
        filter: true,
        valueFormatter: this.handle_V1_Parsing,
      },
      {
        headerName: "V2 Parsing Enabled",
        isVisible: true,
        colId: "v2_parsing",
        field: "settings.v2_parsing",
        sortable: true,
        filter: true,
        valueFormatter: this.handle_V2_Parsing,
      },
      {
        headerName: "Installed date",
        isVisible: true,
        colId: "on_boarded_at",
        field: "on_boarded_at",
        label: "Installed date",
        required: false,
        sortable: true,
        type: "date",
        minWidth: 180,
        filter: true,
      },
      {
        headerName: "Last Communication",
        label: "Last Communication",
        isVisible: true,
        colId: "utc_last_communicated_at",
        field: "utc_last_communicated_at",
        // cellRenderer: "agGridLastCommCellRenderer",
        sortable: true,
        filter: "agDateColumnFilter",
        width: 230,
        filterParams: {
          comparator: (inputDate, cellValue) => {
            const cellDate = this.adm__dateUtilsLib.parse(
              cellValue,
              this.adm__dateUtilsLib.isoFormatString,
              new Date()
            );
            const formattedInputDate = inputDate;
            if (
              this.adm__dateUtilsLib.isBefore(
                this.adm__dateUtilsLib.startOfDay(cellDate),
                formattedInputDate
              )
            ) {
              return -1;
            } else if (
              this.adm__dateUtilsLib.isAfter(
                this.adm__dateUtilsLib.startOfDay(cellDate),
                formattedInputDate
              )
            ) {
              return 1;
            }
            return 0;
          },
          buttons: ["apply", "clear"],
        },
        valueFormatter: this.handleLastCommunication,
      },
      {
        headerName: "Customer Count",
        isVisible: true,
        colId: "customer_count",
        field: "customer_count",
        sortable: true,
        filter: true,
      },
    ];
    this.frameworkComponents = {
      agGridStringCompareFilter: agGridStringCompareFilter,
      agGridLastCommCellRenderer: agGridLastCommCellRenderer,
    };
    this.rowData = this.deviceDetails;
  },
  methods: {
    ...mapActions("superadmin", {
      fetchAllDeviceDetails: "fetchAllDeviceDetails",
    }),
    handleGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi.columnModel
        ? params.columnApi.columnModel
        : params.columnApi.columnController;
      console.log(this.gridOptions);
    },
    handleLastCommunication(params) {
      return this.ftm__formatEmpty(
        this.adm__filterToFormatDate(params.data.utc_last_communicated_at)
      );
    },
    handleChangeColumnSelection(selectedColumnsId) {
      const allColumns = this.columnDefs.map((col, id) => {
        return col.colId;
      });
      const columnsToHide = allColumns.filter(
        (x) => !new Set(selectedColumnsId).has(x)
      );
      this.$nextTick(() => {
        if (
          this.$refs.agGridGatewayFilters &&
          this.gridColumnApi &&
          selectedColumnsId &&
          selectedColumnsId.length > 0
        ) {
          this.gridColumnApi.setColumnsVisible(selectedColumnsId, true);
          this.gridColumnApi.setColumnsVisible(columnsToHide, false);
        }
      });
    },
    handle_V2_Parsing(params) {
      return params.data.settings.v2_parsing;
    },
    handle_V1_Parsing(params) {
      return params.data.settings.v1_parsing;
    },
    getFormattedExcelDataVal(data, field) {
      let retVal = this.$lodash.get(data, field);
      if (
        (field === "settings.wifi_networks" ||
          field === "settings.wifi_passwords" ||
          field === "settings.wifi_security") &&
        retVal !== undefined
      ) {
        retVal = retVal.join();
      } else if (field === "utc_last_communicated_at") {
        //  || field === 'on_boarded_at'
        retVal = this.ftm__formatEmpty(this.adm__filterToFormatDate(retVal));
      }
      return retVal;
    },
    handleDownloadExcel() {
      const columns = this.gridColumnApi.gridColumns.map((column) => [
        column.colDef.field,
        column.colDef.headerName,
      ]);
      const xlData = [];
      this.gridApi.forEachNodeAfterFilter((rowNode, index) => {
        xlData.push(
          columns.reduce((acc, [field, headerName]) => {
            if (
              headerName === "Wifi Networks" ||
              headerName === "Wifi Passwords" ||
              headerName === "Wifi Security"
            ) {
              const data = this.$lodash.get(rowNode.data, field)?.toString();
              acc[headerName] = data;
            } else if (field === "utc_last_communicated_at") {
              const data = this.$lodash.get(rowNode.data, field);
              const IstTimeZone = "Asia/Kolkata";
              if (data) {
                acc[headerName] = this.ftm__formatEmpty(
                  this.adm__formatByTZOnUTCISOWithLocale(
                    data,
                    "dd-MMM-yy HH:mm",
                    IstTimeZone
                  )
                );
              }
              //  acc[headerName] = this.ftm__formatEmpty(this.adm__filterToFormatDate(data));
            } else {
              acc[headerName] = this.$lodash.get(rowNode.data, field);
            }
            return acc;
          }, {})
        );
      });
      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.json_to_sheet(xlData);
      XLSX.utils.book_append_sheet(wb, ws);
      XLSX.writeFile(wb, "GatewaysFilters.xlsx", {
        type: "binary",
        cellDates: true,
      });
    },
    async initComponent() {
      try {
        this.loading = true;
        const response = await this.fetchAllDeviceDetails({
          device_type: "gateway",
          params: {
            get_all: true,
          },
        });
        this.deviceDetails = this.$store.getters["superadmin/getGateways"];
        this.total = response.total;
      } catch (error) {
        this.ehm__errorMessages(error, true);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.item-container {
  display: flex;
  justify-content: space-between;
  margin-top: -10px;
}
</style>
