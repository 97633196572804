<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: pondmotherTableSA.vue
Description:This file contains the details of the pondmother devices
-->
<template>
  <div>
    <dialog-form
      :model="getNewPondMother()"
      :schema="pondMotherFields"
      :showDialog="dialogVisible"
      :loading="dialogBoxLoading"
      title="Add Pond Mother"
      :backendValidations="createPMFormErrors"
      @close-dialog="closeDialog"
      :v1-code-length="6"
      @save-details="handleSaveNewPondMother"
      @clear-validations="handleClearValidations"
      deviceType="POND_MOTHER"
      :shouldErrorHighlightShow="true"
    >
    </dialog-form>

    <settings-table
      ref="erDataTables"
      class="pm-setting-table-main-container is-filters-set-absolute"
      v-loading="loading"
      element-loading-background="white"
      :tableData="tableData"
      :columns="columnsObject"
      :tableProps="elTableProps"
      :addPadingToSearch="true"
      :total="total"
      :button="true"
      :columnselector_require="true"
      :selectedColumnHeadersSortable="true"
      searchProperty="device_code"
      placeHolderForSearch="Search_device_code"
      @cell-click="handleCellClick"
      @reload="handleLoadData"
      @loadData="handleLoadData"
      @cleared="handleLoadData"
      @add-item-click="handleAddClick"
    >
      <template slot="add-buttton">Add PondMother</template>
      <template v-slot:pondmother_code="{ row }">
        <router-link :to="`/admin/pondmothers/${row.data._id}`">
          {{ row.data.code }}
        </router-link>
      </template>
      <template v-slot:v1_id="{ row }">
        {{ row.data.v1_id }}
        {{ row.data.v1_code ? ` (${row.data.v1_code})` : "" }}
      </template>
      <template v-slot:gateway_code="{ row }">
        {{ row.data.gateway_code }}
      </template>
      <template v-slot:installed_date="{ row }">
        {{
          ftm__formatEmpty(
            adm__filterToFormatDate(row.data.on_boarded_at, "dd-MMM-yy")
          )
        }}
      </template>

      <template v-slot:user_details="{ row }">
        {{
          ftm__objectValueByPath(
            row.data.user_details,
            "full_name",
            ftm__formatEmpty("--")
          )
        }}
      </template>
      <template v-slot:prev_day_schedule="{ row }">
        <el-switch
          :value="(row.data.settings || {}).retain_previous_day_schedule"
          active-color="#13ce66"
          inactive-color="#ff4949"
          @input="
            saveSettingsInDb(
              $event,
              row.data.settings,
              row.data,
              'retain_previous_day_schedule'
            )
          "
        ></el-switch>
      </template>
      <template v-slot:ui_edit="{ row }">
        <el-switch
          :value="(row.data.settings || {}).device_ui_edit"
          active-color="#13ce66"
          inactive-color="#ff4949"
          @input="
            saveSettingsInDb(
              $event,
              row.data.settings,
              row.data,
              'device_ui_edit'
            )
          "
        ></el-switch>
      </template>
      <template v-slot:last_communication="{ row }">
        <el-tag
          size="mini"
          type="success"
          effect="dark"
          v-if="row.data.utc_last_communicated_at_status"
        >
          {{
            ftm__formatEmpty(
              adm__filterToFormatDate(row.data.utc_last_communicated_at)
            )
          }}
        </el-tag>
        <template v-else>{{
          ftm__formatEmpty(
            adm__filterToFormatDate(row.data.utc_last_communicated_at)
          )
        }}</template>
      </template>
      <template v-slot:pond_status="{ row }">
        <el-tag
          size="mini"
          type="success"
          effect="dark"
          v-if="row.data.pond_details && row.data.pond_details.status === 'ACTIVE'"
        >
          {{
            ftm__formatEmpty(
              'Active'
            )
          }}
        </el-tag>
        <template v-else-if="row.data.pond_details && row.data.pond_details.status === 'INACTIVE'">{{
          ftm__formatEmpty(
            'Inactive'
          )
        }}</template>
        <template v-else>{{
          ftm__formatEmpty(
            '--'
          )
        }}</template>
      </template>
      <template v-slot:feeder_type="{ row }">
        {{
          ftm__formatEmpty(
            getHopperType(row.data.settings.hopper_type),
            undefined,
            undefined,
            true
          )
        }}
      </template>
    </settings-table>
  </div>
</template>

<script>
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
import adminDatesHandlerMixin from "@/mixins/adminDatesHandlerMixin";
import filtersMixin from "@/mixins/filtersMixin";
import settingsTable from "@/components/base/settingsTable";
import dialogForm from "@/components/superadmin/shared/dialogForm";
import { mapActions, mapGetters } from "vuex";
export default {
  mixins: [errorHandlerMixin, adminDatesHandlerMixin, filtersMixin],
  components: {
    settingsTable,
    dialogForm
  },
  data: function() {
    return {
      createPMFormErrors: {},
      queryData: {},
      tableData: [],
      dialogBoxLoading: false,
      dialogVisible: false,
      total: 0,
      loading: false,
      componentName: "pondMotherDetails",
      componentList: [
        {
          name: "details",
          label: "PondMother Details"
        },
        {
          name: "logs",
          label: "PondMother Logs"
        }
      ]
    };
  },
  computed: {
    ...mapGetters("superadmin", {
      getPondMothers: "getPondMothers"
    }),
    elTableProps() {
      return {
        headerCellClassName: this.headerCellName,
        height: this.getRespTableHeight,
        defaultSort: {
          prop: "title",
          order: "ascending"
        }
      };
    },
    getRespTableHeight() {
      return "calc(100vh - 150px)";
    },

    pondMotherFields: function() {
      return [
        {
          type: "datePicker",
          inputType: "string",
          label: "Installation Date",
          model: "on_boarded_at",
          validationRules: "required"
        },
        {
          type: "input_number",
          inputType: "number",
          props: { min: 10, max: 35 },
          label: "Kg Dispense Time (secs)",
          model: "kg_dispense_time",
          validationRules: "required"
        },
        {
          type: "input_number",
          inputType: "number",
          props: { min: 0 },
          label: "Data Interval (mins)",
          model: "data_interval",
          validationRules: "required"
        },
        {
          type: "select",
          props: {
            options: [
              { label: "PM75", value: "PM75" },
              { label: "PM125", value: "PM125" },
              { label: "PM250 (S)", value: "PM250-S" },
              { label: "PM250 (C)", value: "PM250-C" },
              { label: "PM250 Max (S)", value: "PM250-MAX-S" },
              { label: "PM250 Max (C)", value: "PM250-MAX-C" }
            ],
            disabled: true
          },

          label: "Feeder Type",
          model: "hopper_type",
          validationRules: ""
        }
      ];
    },
    computedComponentName: {
      get() {
        console.log(this.$route);
        return this.componentName;
      },
      set(tabName) {
        this.componentName = tabName;
      }
    },
    columnsObject: function() {
      return [
        {
          prop: "code",
          label: "PondMother Code",
          required: true,
          sortable: true,
          minWidth: 150,
          fixed: "fixed"
        },
        {
          prop: "title",
          label: this.$t("Alias"),
          required: true,
          minWidth: 120
        },
        {
          prop: "user_details.full_name",
          label: "Customer Name",
          required: true,
          minWidth: 200
        },
        {
          prop: "user_details.country.name",
          label: "Country",
          required: true,
          minWidth: 120
        },
        {
          prop: "user_details.timezone.name",
          label: "Customer Timezone",
          required: true,
          minWidth: 200
        },
        {
          prop: "device_timezone",
          label: "Device Timezone",
          required: true,
          minWidth: 200
        },
        {
          prop: "pond_details.title",
          label: "Pond Name",
          required: true,
          minWidth: 200
        },
        {
          prop: "pond_details.status",
          label: "Pond Status",
          required: true,
          minWidth: 200
        },
        {
          prop: "dealer_details.full_name",
          label: "Dealer Name",
          required: true,
          minWidth: 200
        },
        {
          prop: "location_details.name",
          label: "Location",
          required: true,
          minWidth: 200
        },
        {
          prop: "settings.device_ui_edit",
          label: "UI Edit",
          required: true,
          minWidth: 100
        },
        {
          prop: "settings.retain_previous_day_schedule",
          label: "Prev Day Schedule",
          required: true,
          minWidth: 100
        },
        {
          prop: "v1_id",
          label: "V1 Id",
          required: false,
          minWidth: 200
        },
        {
          prop: "_id",
          label: "V2 Id",
          required: false,
          minWidth: 200
        },
        {
          prop: "settings.hopper_type",
          label: "Feeder Type",
          required: true,
          minWidth: 200
        },
        {
          prop: "gateway_code",
          label: "Gateway Code",
          sortable: true,
          required: true,
          minWidth: 120
        },
        {
          prop: "settings.software_version",
          label: "Software Version",
          required: true,
          minWidth: 100
        },
        {
          prop: "settings.hardware_version",
          label: "Hardware Version",
          required: true,
          minWidth: 100
        },
        {
          prop: "settings.mechanical_version",
          label: "Mechanical Version",
          required: false,
          minWidth: 100
        },
        {
          prop: "shrimp_talk_code",
          label: "Shrimp talk Id",
          required: true,
          minWidth: 100
        },
        {
          prop: "on_boarded_at",
          label: "Installed date",
          required: true,
          sortable: true,
          type: "date",
          minWidth: 150
        },
        {
          prop: "utc_last_communicated_at",
          label: "Last Communication",
          required: true,
          sortable: "custom",
          type: "date",
          minWidth: 150
        },
        {
          prop: "date",
          label: "Date",
          required: true,
          sortable: "custom",
          type: "date",
          minWidth: 150
        }
        // {
        //   prop: "dateIst",
        //   label: "Date IST",
        //   required: true,
        //   sortable: "custom",
        //   type: "date",
        //   minWidth: 150
        // }
      ];
    }
  },
  methods: {
    ...mapActions("superadmin", {
      fetchAllDeviceDetails: "fetchAllDeviceDetails",
      clearDeviceDetails: "clearDeviceDetails",
      saveListDevicesData: "saveListDevicesData",
      saveSingleDeviceSettingsData: "saveSingleDeviceSettingsData"
    }),
    getHopperType(input) {
      const hopperTypeObject = {
        PM125: "PM125",
        "PM250-S": "PM250 (S)",
        "PM250-C": "PM250 (C)",
        "PM250-MAX-S": "PM250 Max (S)",
        "PM250-MAX-C": "PM250 Max (C)",
        PM75: "PM75"
      };
      return hopperTypeObject[input];
    },
    getNewPondMother() {
      return {
        on_boarded_at: this.adm__dateUtilsLib.formatDate(
          new Date(),
          "yyyy-MM-dd"
        ),
        kg_dispense_time: 24,
        data_interval: 5,
        hopper_type: "PM125"
      };
    },
    closeDialog() {
      this.createPMFormErrors = {};
      this.dialogVisible = false;
    },
    handleClearValidations(type, indexPosition) {
      switch (type) {
        case "ALL":
          this.createPMFormErrors = {};
          break;
        case "SINGLE":
          delete this.createPMFormErrors[indexPosition];
          break;
      }
    },
    handleAddClick() {
      this.dialogVisible = true;
    },
    async handleSaveNewPondMother(model, arrNames) {
      this.createPMFormErrors = {};
      try {
        this.dialogBoxLoading = true;
        const payload = arrNames.map((name) => {
          return {
            v1_code: name,
            on_boarded_at: model.on_boarded_at,
            // hopper_type: model.hopper_type,
            settings: {
              kg_dispense_time: model.kg_dispense_time,
              data_interval: model.data_interval * 60
            }
          };
        });
        await this.saveListDevicesData({
          device_type: "POND_MOTHER",
          payload
        });

        this.$notify({
          title: "Success",
          message: "Created PondMothers Successfully",
          type: "success"
        });
        this.dialogVisible = false;
      } catch (err) {
        this.$notify({
          title: "Failed",
          message: "Created PondMothers UnSuccessful",
          type: "error"
        });

        if (err.response && err.response.data) {
          this.createPMFormErrors = err.response.data.errors.details.reduce(
            (acc, err) => {
              acc[err.key] = err.message;
              return acc;
            },
            {}
          );
        }
      } finally {
        this.dialogBoxLoading = false;
      }
    },
    async handleLoadData(query) {
      try {
        this.loading = true;
        this.queryData = query;
        // query.order_by = "code";
        // query.order_type = "asc";
        const response = await this.fetchAllDeviceDetails({
          device_type: "pond_mother",
          infinite_scroll: false,
          params: query
        });
        this.tableData = this.getPondMothers;
        const IstTimeZone = "Asia/Kolkata";
        this.tableData.map((item, i) => {
          if (item?.utc_last_communicated_at) {
            item.date = this.ftm__formatEmpty(
              this.adm__formatByTZOnUTCISOWithLocale(
                item.utc_last_communicated_at,
                "dd-MMM-yy HH:mm",
                item.user_id?.timezone?.name
              )
            );
            item.dateIst = this.ftm__formatEmpty(
              this.adm__formatByTZOnUTCISOWithLocale(
                item.utc_last_communicated_at,
                "dd-MMM-yy HH:mm",
                IstTimeZone
              )
            );
          }
        });
        this.total = response.total;
      } catch (error) {
        this.ehm__errorMessages(error, true);
      } finally {
        this.refreshTableLayout();
        this.loading = false;
      }
    },
    refreshTableLayout() {
      this.$nextTick(() => {
        if (this.$refs.erDataTables) {
          this.$refs.erDataTables.$refs.dataTable.$refs.elTable.doLayout();
        }
      });
    },
    handleCellClick(row, column, cell, event) {
      if (column.label === this.columnsObject[0].label) {
        this.$store.dispatch("superadmin/clearDeviceDetails", {
          device_type: "pond_mother"
        });
        // this.$router.push({
        //   name: "PondMotherDetails",
        //   params: { pm_id: row._id }
        // });
      }
    },
    handleTabClick(tab) {
      // console.log(tab);
      this.clearDeviceDetails({
        device_type: "pond_mother"
      });
    },
    handleSelectedComponent(component) {
      this.componentName = component;
    },
    async handleDeviceClick(event, { device_code, device_type }) {
      const deviceRouteStr = {
        gateway: "/admin/gateways/",
        pond_mother: "/admin/pondmothers/",
        pond_guard: "/admin/pondguards/",
        shrimp_talk: "/admin/shrimptalks/"
      };
      const responseDeviceKey = {
        gateway: "gateways",
        pond_mother: "pond_mothers",
        pond_guard: "pond_guards",
        shrimp_talk: "shrimp_talks"
      };
      const response = await this.fetchAllDeviceDetails({
        device_type: device_type,
        params: { device_code: device_code }
      });
      this.clearDeviceDetails({
        device_type: device_type
      });
      this.$router.push(
        `${deviceRouteStr[device_type]}${
          response[responseDeviceKey[device_type]][0]._id
        }`
      );
    },
    async saveSettingsInDb(value, settings = {}, rowData, key) {
      await this.$confirm(
        "These changes will effect the behaviour of the device, Are you sure?",
        "Warning",
        {
          confirmButtonText: "Save",
          cancelButtonText: this.$t("Comn_cancel"),
          type: "warning"
        }
      );
      const payload = { ...settings };
      payload[key] = value;
      try {
        this.loading = true;
        const response = await this.saveSingleDeviceSettingsData({
          device_code: rowData.code,
          payload,
          settingsType: "POND_MOTHER"
        });
        this.$notify({
          title: "Success",
          dangerouslyUseHTMLString: true,
          message: response.data.message,
          duration: 3000,
          type: "success"
        });
        await this.handleLoadData(this.queryData);
      } catch (err) {
        this.$notify({
          title: "Error",
          dangerouslyUseHTMLString: true,
          message: "Error while saving the data",
          duration: 3000,
          type: "success"
        });
        this.ehm__errorMessages(err, true);
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>

<style lang="scss">
.el-table__fixed {
  height: 732px !important;
}
.pagination-wrap {
  margin-bottom: 5px;
}
.pm-setting-table-main-container {
  //
}
// .el-table__body-wrapper {
//   // height: 510px !important;
// }
// .el-table__fixed {
//   height: 380px !important;
// }
.admin-header {
  .el-menu {
    z-index: 0 !important;
  }
}

// .el-menu {
//   margin-bottom: -37px !important;
// }
.padding-10-0 {
  padding: 4px 12px !important;
}
.pond-mother-table-sa {
  .btn-gateway-code {
    color: #606266;
    font-weight: normal;
    letter-spacing: 0.1px;
    font-size: 12px;
  }
}
</style>
