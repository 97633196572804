<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: customerDetails.vue
Description:This file contains the combination of basicCustomerDetails,devices count and individual devices details of the customer
-->
<template>
  <div class="customer-details-tabs">
    <!-- <portal to="outsideMainScroll"> -->
    <basic-customer-details
      :showDeviceCode="false"
      :userDetails="customerDetails"
      :showCustomerDetails="true"
      :hideDeviceDetails="true"
      :allowAddCustomer="false"
    >
      <template slot="moreDetails">
        <div>
          <el-row class="details__heading">Location</el-row>
          <el-row class="details__heading__value">
            {{ (customerDetails.country || { name: "" }).name || emptyStr }}
          </el-row>
        </div>
      </template>
    </basic-customer-details>
    <!-- </portal> -->
    <el-scrollbar
      class="customer-details customer-details-scroll"
      viewClass="customer-details-scroll-view"
      wrapClass="customer-details-scroll-wrap"
    >
      <div class="device-count">
        <div>
          <div>PondMother</div>
          <div class="total_count">{{ deviceCount.pond_mothers_count }}</div>
        </div>
        <div>
          <div>PondGuard</div>
          <div class="total_count">{{ deviceCount.pond_guards_count }}</div>
        </div>
        <div>
          <div>Shrimptalk</div>
          <div class="total_count">
            <div>{{ deviceCount.shrimp_talks_count }}</div>
            <div>
              (<span>v1-{{ deviceCount.v1_enabled_shrimp_talks_count }}</span
              >) (<span
                >v2 -{{ deviceCount.v2_enabled_shrimp_talks_count }}</span
              >)
            </div>
          </div>
        </div>
        <div>
          <div>Gateway</div>
          <div class="total_count">
            <div>{{ deviceCount.gateways_count }}</div>
            <div>
              (<span>v1-{{ deviceCount.v1_enabled_gateways_count }}</span
              >) (<span>v2-{{ deviceCount.v2_enabled_gateways_count }}</span
              >)
            </div>
          </div>
        </div>
      </div>
      <el-tabs v-model="activeName">
        <el-tab-pane label="Pond Mother" name="pond_mother">
          <el-row>
            <small-table
              :tableData="arrDeviceDetails"
              :columns="columns"
              :total="arrDeviceDetails.length"
              size="mini"
              :page-size="30"
              :height="'calc(100vh - 170px)'"
              :search-on-property="searchDeviceProperty"
              :actions-column="false"
              :table-props="tablePropsForSmallTable"
              style="width: 100%"
              v-loading="loading"
              element-loading-background="white"
            >
              <template v-slot:device_type="{ row }">
                {{ deviceTypeMap[row.data.device_type] }}
              </template>

              <template v-slot:v1_id="{ row }">
                <template
                  v-if="deviceTypeMap[row.data.device_type] === 'ShrimpTalk'"
                >
                  ST{{ row.data.v1_code }}
                </template>
              </template>

              <template v-slot:alias_name="{ row }">
                <router-link
                  :to="`${getURLPath[row.data.device_type]}${row.data._id}`"
                  >{{ row.data.code }}</router-link
                >
              </template>
              <template v-slot:feeder_type="{ row }">
                {{ getHopperType(row.data.settings.hopper_type) }}
              </template>

              <template v-slot:last_communication="{ row }">
                <template
                  v-if="
                    row.data.last_communicated_at ||
                      row.data.utc_last_communicated_at
                  "
                >
                  <el-tag
                    size="mini"
                    type="success"
                    v-if="row.data.comm_status"
                  >
                    {{
                      ftm__formatEmpty(
                        adm__filterToFormatDate(
                          row.data.utc_last_communicated_at ||
                            row.data.last_communicated_at
                        )
                      )
                    }}
                  </el-tag>
                  <span v-else>
                    {{
                      ftm__formatEmpty(
                        adm__filterToFormatDate(
                          row.data.utc_last_communicated_at ||
                            row.data.last_communicated_at
                        )
                      )
                    }}
                  </span>
                </template>
                <template v-else> -- </template>
              </template>

              <template v-slot:actions="{ row }">
                <el-button-group size="mini">
                  <el-button
                    size="mini"
                    icon="el-icon-plus"
                    @click="handleAddDevices"
                  ></el-button>
                  <el-button
                    size="mini"
                    icon="el-icon-delete"
                    @click="handleRemoveDevices(row.data)"
                  ></el-button>
                </el-button-group>
              </template>
            </small-table>
            <!-- <el-divider direction="horizontal"></el-divider>
        <single-device-settings-table
          :settings-details="customerDetails"
          :keys="customerKeys"
        ></single-device-settings-table> -->
          </el-row>
        </el-tab-pane>
        <el-tab-pane label="Pond Guard" name="pond_guard">
          <el-row>
            <small-table
              :tableData="arrDeviceDetails"
              :columns="columns"
              :total="arrDeviceDetails.length"
              size="mini"
              :page-size="30"
              :height="undefined"
              :search-on-property="searchDeviceProperty"
              :actions-column="false"
              :table-props="tablePropsForSmallTable"
              style="width: 100%"
              v-loading="loading"
              element-loading-background="white"
            >
              <template v-slot:device_type="{ row }">
                {{ deviceTypeMap[row.data.device_type] }}
              </template>
              <template v-slot:v1_id="{ row }">
                <template
                  v-if="deviceTypeMap[row.data.device_type] === 'ShrimpTalk'"
                >
                  ST{{ row.data.v1_code }}
                </template>
              </template>

              <template v-slot:alias_name="{ row }">
                <router-link
                  :to="`${getURLPath[row.data.device_type]}${row.data._id}`"
                  >{{ row.data.code }}</router-link
                >
              </template>

              <template v-slot:last_communication="{ row }">
                <template
                  v-if="
                    row.data.last_communicated_at ||
                      row.data.utc_last_communicated_at
                  "
                >
                  <el-tag
                    size="mini"
                    type="success"
                    v-if="row.data.comm_status"
                  >
                    {{
                      ftm__formatEmpty(
                        adm__filterToFormatDate(
                          row.data.utc_last_communicated_at ||
                            row.data.last_communicated_at
                        )
                      )
                    }}
                  </el-tag>
                  <span v-else>
                    {{
                      ftm__formatEmpty(
                        adm__filterToFormatDate(
                          row.data.utc_last_communicated_at ||
                            row.data.last_communicated_at
                        )
                      )
                    }}
                  </span>
                </template>
                <template v-else> -- </template>
              </template>
              <template v-slot:actions="{ row }">
                <el-button-group size="mini">
                  <el-button
                    size="mini"
                    icon="el-icon-plus"
                    @click="handleAddDevices"
                  ></el-button>
                  <el-button
                    size="mini"
                    icon="el-icon-delete"
                    @click="handleRemoveDevices(row.data)"
                  ></el-button>
                </el-button-group>
              </template>
            </small-table>
            <!-- <el-divider direction="horizontal"></el-divider>
        <single-device-settings-table
          :settings-details="customerDetails"
          :keys="customerKeys"
        ></single-device-settings-table> -->
          </el-row>
        </el-tab-pane>
        <el-tab-pane label="Shrimp Talk" name="shrimp_talk">
          <el-row>
            <small-table
              :tableData="arrDeviceDetails"
              :columns="columns"
              :total="arrDeviceDetails.length"
              size="mini"
              :page-size="30"
              :height="undefined"
              :search-on-property="searchDeviceProperty"
              :actions-column="false"
              :table-props="tablePropsForSmallTable"
              style="width: 100%"
              v-loading="loading"
              element-loading-background="white"
            >
              <template v-slot:device_type="{ row }">
                {{ deviceTypeMap[row.data.device_type] }}
              </template>
              <template v-slot:v1_id="{ row }">
                <template
                  v-if="deviceTypeMap[row.data.device_type] === 'ShrimpTalk'"
                >
                  ST{{ row.data.v1_code }}
                </template>
              </template>

              <template v-slot:alias_name="{ row }">
                <router-link
                  :to="`${getURLPath[row.data.device_type]}${row.data._id}`"
                  >{{ row.data.code }}</router-link
                >
              </template>

              <template v-slot:last_communication="{ row }">
                <template
                  v-if="
                    row.data.last_communicated_at ||
                      row.data.utc_last_communicated_at
                  "
                >
                  <el-tag
                    size="mini"
                    type="success"
                    v-if="row.data.comm_status"
                  >
                    {{
                      ftm__formatEmpty(
                        adm__filterToFormatDate(
                          row.data.utc_last_communicated_at ||
                            row.data.last_communicated_at
                        )
                      )
                    }}
                  </el-tag>
                  <span v-else>
                    {{
                      ftm__formatEmpty(
                        adm__filterToFormatDate(
                          row.data.utc_last_communicated_at ||
                            row.data.last_communicated_at
                        )
                      )
                    }}
                  </span>
                </template>
                <template v-else> -- </template>
              </template>

              <template v-slot:v2_parsing_enabled="{ row }">
                <el-switch
                  :value="
                    row.data.settings.v2_parsing
                      ? row.data.settings.v2_parsing
                      : false
                  "
                  @input="
                    handleASettingKeyChange($event, row.data, 'v2_parsing')
                  "
                ></el-switch>
              </template>

              <template v-slot:v1_parsing_enabled="{ row }">
                <el-switch
                  :value="
                    row.data.settings.v1_parsing
                      ? row.data.settings.v1_parsing
                      : false
                  "
                  @input="
                    handleASettingKeyChange($event, row.data, 'v1_parsing')
                  "
                ></el-switch>
              </template>
              <template v-slot:actions="{ row }">
                <el-button-group size="mini">
                  <el-button
                    size="mini"
                    icon="el-icon-plus"
                    @click="handleAddDevices"
                  ></el-button>
                  <el-button
                    size="mini"
                    icon="el-icon-delete"
                    @click="handleRemoveDevices(row.data)"
                  ></el-button>
                </el-button-group>
              </template>
            </small-table>
            <!-- <el-divider direction="horizontal"></el-divider>
        <single-device-settings-table
          :settings-details="customerDetails"
          :keys="customerKeys"
        ></single-device-settings-table> -->
          </el-row>
        </el-tab-pane>
        <el-tab-pane label="Gateway" name="gateway">
          <el-row>
            <small-table
              :tableData="arrDeviceDetails"
              :columns="columns"
              :total="arrDeviceDetails.length"
              size="mini"
              :page-size="30"
              :height="undefined"
              :search-on-property="searchDeviceProperty"
              :actions-column="false"
              :table-props="tablePropsForSmallTable"
              style="width: 100%"
              v-loading="loading"
              element-loading-background="white"
            >
              <template v-slot:device_type="{ row }">
                {{ deviceTypeMap[row.data.device_type] }}
              </template>
              <template v-slot:v1_id="{ row }">
                <template
                  v-if="deviceTypeMap[row.data.device_type] === 'ShrimpTalk'"
                >
                  ST{{ row.data.v1_code }}
                </template>
              </template>

              <template v-slot:alias_name="{ row }">
                <router-link
                  :to="`${getURLPath[row.data.device_type]}${row.data._id}`"
                  >{{ row.data.code }}</router-link
                >
              </template>

              <template v-slot:last_communication="{ row }">
                <template
                  v-if="
                    row.data.last_communicated_at ||
                      row.data.utc_last_communicated_at
                  "
                >
                  <el-tag
                    size="mini"
                    type="success"
                    v-if="row.data.comm_status"
                  >
                    {{
                      ftm__formatEmpty(
                        adm__filterToFormatDate(
                          row.data.utc_last_communicated_at ||
                            row.data.last_communicated_at
                        )
                      )
                    }}
                  </el-tag>
                  <span v-else>
                    {{
                      ftm__formatEmpty(
                        adm__filterToFormatDate(
                          row.data.utc_last_communicated_at ||
                            row.data.last_communicated_at
                        )
                      )
                    }}
                  </span>
                </template>
                <template v-else> -- </template>
              </template>
              <template v-slot:v2_parsing_enabled="{ row }">
                <el-switch
                  :value="
                    row.data.settings.v2_parsing
                      ? row.data.settings.v2_parsing
                      : false
                  "
                  @input="
                    handleASettingKeyChange($event, row.data, 'v2_parsing')
                  "
                ></el-switch>
              </template>
              <template v-slot:v1_parsing_enabled="{ row }">
                <el-switch
                  :value="
                    row.data.settings.v1_parsing
                      ? row.data.settings.v1_parsing
                      : false
                  "
                  @input="
                    handleASettingKeyChange($event, row.data, 'v1_parsing')
                  "
                ></el-switch>
              </template>
              <template v-slot:actions="{ row }">
                <el-button-group size="mini">
                  <el-button
                    size="mini"
                    icon="el-icon-plus"
                    @click="handleAddDevices"
                  ></el-button>
                  <el-button
                    size="mini"
                    icon="el-icon-delete"
                    @click="handleRemoveDevices(row.data)"
                  ></el-button>
                </el-button-group>
              </template>
            </small-table>
            <!-- <el-divider direction="horizontal"></el-divider>
        <single-device-settings-table
          :settings-details="customerDetails"
          :keys="customerKeys"
        ></single-device-settings-table> -->
          </el-row>
        </el-tab-pane>
        <el-tab-pane label="Shrimp Talk Table" name="shrimptalks2">
          <customerShrimpTalkAG :deviceDetails="arrDeviceDetails" />
        </el-tab-pane>
        <el-tab-pane label="GatewayTable" name="gateways2">
          <customerGatewayAG :deviceDetails="arrDeviceDetails" />
        </el-tab-pane>
        <el-tab-pane label="PondMotherTable" name="pondmothers2">
          <customerPondMotherAG :deviceDetails="arrDeviceDetails" />
        </el-tab-pane>
        <el-tab-pane label="PondGuardTable" name="pondguards2">
          <customerPondGuardAG :deviceDetails="arrDeviceDetails" />
        </el-tab-pane>
      </el-tabs>
    </el-scrollbar>
    <div>
      <component
        :is="component"
        :showDialog="dialogVisible"
        :action="action"
        :customer_details="customerDetails"
        @close_dialog="closeDialog"
      ></component>
    </div>
  </div>
</template>

<script>
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
import filtersMixin from "@/mixins/filtersMixin";
import adminDatesHandlerMixin from "@/mixins/adminDatesHandlerMixin";
import { mapGetters, mapActions } from "vuex";
import User from "@/model/user";
import ShrimpTalk from "@/model/shrimptalk";
import PondMother from "@/model/pondmother";
import PondGuard from "@/model/pondguard";
import Gateway from "@/model/gateway";
import smallTable from "@/components/superadmin/shared/smallTable";
import basicCustomerDetails from "@/components/superadmin/shared/basicCustomerDetails";
import singleDeviceSettingsTable from "@/components/superadmin/shared/singleDeviceSettingsTable";
import addDevicesToUser from "./addDevicesToUser";
import customerGatewayAG from "./customerGatewayAG";
import customerShrimpTalkAG from "./customerShrimpTalkAG";
import customerPondGuardAG from "./customerPondGuardAG";
import customerPondMotherAG from "./customerPondMotherAG";
// import pgCustomerOxygenCharts from "@/components/pgCustomerOxygenCharts";
export default {
  mixins: [errorHandlerMixin, filtersMixin, adminDatesHandlerMixin],
  props: {
    customerDetails: {
      default: () => new User()
    }
  },
  components: {
    smallTable,
    basicCustomerDetails,
    singleDeviceSettingsTable,
    addDevicesToUser,
    customerGatewayAG,
    customerShrimpTalkAG,
    customerPondGuardAG,
    customerPondMotherAG
    // pgCustomerOxygenCharts
  },
  data: function() {
    return {
      newShrimpTalk: new ShrimpTalk(),
      newPondMother: new PondMother(),
      newPondGuard: new PondGuard(),
      newGateway: new Gateway(),
      dialogVisible: false,
      action: "ADD_DEVICES",
      activeName: "pond_mother",
      loading: false,
      component: "addDevicesToUser",
      emptyStr: "--",
      searchDeviceProperty: "code",
      openCollapseItems: ["Shrimptalk", "PondMother", "PondGuard", "Gateway"],
      tablePropsForSmallTable: {
        defaultSort: {
          prop: "utc_last_communicated_at",
          order: "descending"
        }
      },
      getURLPath: {
        gateway: "/admin/gateways/",
        pond_mother: "/admin/pondmothers/",
        pond_guard: "/admin/pondguards/",
        shrimp_talk: "/admin/shrimptalks/"
      }
    };
  },
  computed: {
    ...mapGetters("superadmin", {
      getCustomers: "getUsers",
      getCustomersObj: "getUsersObj",
      getUserIdIndexPmsObj: "getUserIdIndexPmsObj",
      getUserIdIndexPgsObj: "getUserIdIndexPgsObj",
      getUserIdIndexStsObj: "getUserIdIndexStsObj",
      getUserIdIndexGatewaysObj: "getUserIdIndexGatewaysObj",
      getUserParsingKeysCount: "getUserParsingKeysCount"
    }),
    deviceTypeMap: function() {
      return {
        gateway: "Gateway",
        pond_mother: "PondMother",
        pond_guard: "PondGuard",
        shrimp_talk: "ShrimpTalk"
      };
    },
    deviceCount: function() {
      return this.getUserParsingKeysCount;
    },

    customerKeys: function() {
      return {
        email: "string",
        phone: "string",
        first_name: "string",
        last_name: "string",
        "country.name": "object",
        "timezone.name": "object"
      };
    },
    arrDeviceDetails() {
      const customerDetails = this.$lodash.cloneDeep(this.customerDetails);
      const deviceKeyToTab = {
        gateway: "gateway",
        shrimp_talk: "shrimp_talk",
        shrimptalks2: "shrimp_talk",
        gateways2: "gateway",
        pondmothers2: "pond_mother",
        pondguards2: "pond_guard",
        pond_mother: "pond_mother",
        pond_guard: "pond_guard"
      };
      return [deviceKeyToTab[this.activeName]]
        .map(x =>
          (customerDetails[x + "s"] || []).map(item => {
            item.device_type = x;
            return item;
          })
        )
        .flat(1)
        .map(x => {
          x.comm_status = this.getStatusBasedOnDate(
            x.last_communicated_at || x.utc_last_communicated_at,
            x.device_type
          );
          return x;
        });
    },
    columns() {
      return [
        {
          prop: "device_type",
          label: "Device Type",
          required: true,
          sortable: true,
          minWidth: 120
        },
        {
          prop: "title",
          label: "Alias Name",
          required: true,
          sortable: true,
          minWidth: 120
        },
        {
          prop: "code",
          label: "Device Code",
          required: true,
          sortable: true,
          minWidth: 120
        },
        {
          prop: "v1_id",
          label: "V1 ID",
          required: true,
          sortable: true,
          minWidth: 100
        },
        {
          prop: "_id",
          label: "V2 ID",
          required: true,
          sortable: true,
          minWidth: 200
        },
        {
          prop: "hopper_type",
          label: "Feeder Type",
          required: this.callPM(),
          sortable: false,
          minWidth: 150
        },

        {
          prop: "settings.software_version",
          label: "Software Version",
          required: true,
          sortable: true,
          minWidth: 150
        },
        {
          prop: "settings.hardware_version",
          label: "Hardware Version",
          required: true,
          sortable: true,
          minWidth: 170
        },
        {
          prop: "utc_last_communication",
          label: "Last Communication",
          required: true,
          sortable: true,
          minWidth: 170
        },
        {
          prop: "settings.v1_parsing",
          label: "V1 Parsing Enabled",
          required: this.callColumn(),
          minWidth: 100
        },
        {
          prop: "settings.v2_parsing",
          label: "V2 Parsing Enabled",
          required: this.callColumn(),
          minWidth: 100
        },
        { label: "Actions", required: true, sortable: true, minWidth: 150 }
      ];
    },
    pondMotherColumns() {
      return [
        {
          prop: "code",
          label: "PondMother Code",
          required: true,
          sortable: true
        },
        {
          prop: "title",
          label: "Alias",
          required: true,
          sortable: true
        },
        {
          prop: "v1_id",
          label: "V1 Id",
          required: true,
          sortable: true
        },

        {
          prop: "settings.software_version",
          label: "Software version",
          required: true,
          sortable: true
        },
        {
          prop: "settings.hardware_version",
          label: "Hardware version",
          required: true,
          sortable: true
        },
        {
          prop: "utc_last_communicated_at",
          label: "Last Communication",
          required: true,
          type: "date",
          device_type: "pond_mother",
          sortable: true
        }
      ];
    },
    pondGuardColumns() {
      return [
        {
          prop: "code",
          label: "PondGuard Code",
          required: true,
          sortable: true
        },
        {
          prop: "title",
          label: "Alias",
          required: true,
          sortable: true
        },
        {
          prop: "v1_id",
          label: "V1 Id",
          required: true,
          sortable: true
        },
        {
          prop: "settings.software_version",
          label: "Software version",
          required: true,
          sortable: true
        },
        {
          prop: "settings.hardware_version",
          label: "Hardware version",
          required: true,
          sortable: true
        },
        {
          prop: "utc_last_communicated_at",
          label: "Last Communication",
          required: true,
          type: "date",
          device_type: "pond_guard",
          sortable: true
        }
      ];
    },
    gatewayColumns() {
      return [
        {
          prop: "code",
          label: "Gateway Code",
          required: true,
          sortable: true
        },
        {
          prop: "title",
          label: "Alias",
          required: true,
          sortable: true
        },
        {
          prop: "v1_id",
          label: "V1 Id",
          required: true,
          sortable: true
        },
        {
          prop: "settings.software_version",
          label: "Software version",
          required: true,
          sortable: true
        },
        {
          prop: "settings.hardware_version",
          label: "Hardware version",
          required: true,
          sortable: true
        },
        {
          prop: "settings.v2_parsing",
          label: "V2 Parsing Enabled",
          required: true
        },

        {
          prop: "utc_last_communicated_at",
          label: "Last Communication",
          required: true,
          type: "date",
          device_type: "gateway",
          sortable: true
        },
        {
          prop: "settings.v2_parsing",
          label: "V2 Parsing Enabled",
          required: true
        }
      ];
    }
  },
  methods: {
    ...mapActions("superadmin", {
      fetchAllDeviceDetails: "fetchAllDeviceDetails",
      clearDeviceDetails: "clearDeviceDetails",
      deleteDevicesToUser: "deleteDevicesToUser",
      updateASettingKey: "updateASettingKey"
    }),
    callPM() {
      if (this.activeName === "pond_mother") {
        return true;
      } else {
        return false;
      }
    },
    callColumn() {
      if (
        this.activeName === "pond_mother" ||
        this.activeName === "pond_guard"
      ) {
        return false;
      } else {
        return true;
      }
    },
    getHopperType(input) {
      const hopperTypeObject = {
        PM125: "PM125",
        "PM250-S": "PM250 (S)",
        "PM250-C": "PM250 (C)",
        "PM250-MAX-S": "PM250 Max (S)",
        "PM250-MAX-C": "PM250 Max (C)",
        PM75: "PM75"
      };
      return hopperTypeObject[input];
    },
    async handleChangeStatus(event, device) {
      this.loading = true;
      const payload = {
        code: device.code,
        v2_parsing: event
      };
      await this.updateASettingKey({
        payload,
        device_type: this.activeName,
        setting_key: "v2_parsing"
      });
      this.$emit("refresh");
      this.loading = false;
    },

    async handleChangev1Status(event, device) {
      this.loading = true;
      const payload = {
        code: device.code,
        v1_parsing: event
      };
      await this.updateASettingKey({
        payload,
        device_type: this.activeName,
        setting_key: "v1_parsing"
      });
      this.$emit("refresh");
      this.loading = false;
    },
    async handleASettingKeyChange(value, device, settingKey) {
      const displayCode = {
        gateway: device.code,
        shrimp_talk: `ST${device.v1_code}`
      };
      const confirmMessage = {
        v1_parsing: {
          warning: `Your are ${
            value ? "enabling" : "disabling"
          } v1 parsing for ${displayCode[this.activeName]}, Are you sure?`,
          success: `V1 parsing is ${value ? "enabled" : "disabled"} for ${
            displayCode[this.activeName]
          }`
        },
        v2_parsing: {
          warning: `Your are ${
            value ? "enabling" : "disabling"
          } v2 parsing for ${displayCode[this.activeName]}, Are you sure?`,
          success: `V2 parsing is ${value ? "enabled" : "disabled"} for ${
            displayCode[this.activeName]
          }`
        }
      };
      const payload = {
        code: device.code,
        [settingKey]: value
      };
      try {
        await this.$confirm(confirmMessage[settingKey].warning, "Warning", {
          confirmButtonText: "ok",
          cancelButtonText: this.$t("Comn_cancel"),
          type: "warning"
        });
        this.loading = true;
        await this.updateASettingKey({
          device_type: this.activeName,
          setting_key: settingKey,
          payload
        });
        this.$notify({
          type: "success",
          title: "Success",
          message: confirmMessage[settingKey].success
        });
        await this.$emit("refresh");
      } catch (error) {
        if (error !== "cancel") {
          const errorCode = error.response.data.status_code;
          let message;
          switch (errorCode) {
            case 409:
              message = error.response.data.message;
              this.$notify({
                type: "error",
                title: "Failed",
                message
              });
              break;
            default:
              this.ehm__errorMessages(error, true);
          }
        }
      } finally {
        this.loading = false;
      }
    },
    async handleRemoveDevices(deviceDetails) {
      try {
        await this.$confirm(
          "This will permanently remove the Device. Continue?",
          "Warning",
          {
            confirmButtonText: "OK",
            cancelButtonText: "Cancel",
            type: "warning"
          }
        );
        this.loading = true;
        await this.deleteDevicesToUser({
          user_id: this.customerDetails._id,
          payload: [deviceDetails._id],
          device_type: deviceDetails.device_type.toUpperCase()
        });
        this.$notify({
          type: "success",
          message: "Device is unassigned from the customer successfully",
          title: "Success"
        });
        this.$emit("refresh");
      } catch (err) {
        console.log(err);
        if (err !== "cancel") {
          this.$notify({
            type: "error",
            message: "Failed to unassign customer",
            title: "Failed"
          });
          this.ehm__errorMessages(err, true);
        }
      } finally {
        this.loading = false;
      }
    },
    handleAddDevices(customer) {
      try {
        this.dialogVisible = true;
        this.action = "ADD_DEVICES";
      } catch (error) {
        this.ehm__errorMessages(error, true);
      }
    },
    async closeDialog(event) {
      this.dialogVisible = false;
      if (event !== "close") {
        this.$emit("refresh");
      }
    },
    getInactiveTimeToCheck(device_type) {
      let time = 1800;
      switch (device_type) {
        case "pond_mother":
          time = 1800;
          break;
        case "pond_guard":
          time = 1800;
          break;
        case "shrimp_talk":
          time = 900;
          break;
        default:
          time = 1800;
          break;
      }
      return time;
    },
    getStatusBasedOnDate(date, device_type) {
      if (!date) {
        return false;
      }
      try {
        const oldDate = new Date(date);
        const currentDate = new Date();
        return (
          (currentDate - oldDate) / 1000 <
          this.getInactiveTimeToCheck(device_type, device_type)
        );
      } catch (err) {
        return false;
      }
    },
    async handleDeviceClick(event, { device_code, device_type }) {
      const deviceRouteStr = {
        gateway: "/admin/gateways/",
        pond_mother: "/admin/pondmothers/",
        pond_guard: "/admin/pondguards/",
        shrimp_talk: "/admin/shrimptalks/"
      };
      const responseDeviceKey = {
        gateway: "gateways",
        pond_mother: "pond_mothers",
        pond_guard: "pond_guards",
        shrimp_talk: "shrimp_talks"
      };
      const response = await this.fetchAllDeviceDetails({
        device_type: device_type,
        params: { device_code: device_code }
      });
      this.clearDeviceDetails({
        device_type: device_type
      });
      this.$router.push(
        `${deviceRouteStr[device_type]}${
          response[responseDeviceKey[device_type]][0]._id
        }`
      );
    },
    handleCustomerClick(userId) {
      this.$router.push(`/admin/customers/${userId}`);
    }
  }
};
</script>

<style lang="scss">
.customer-details {
  &.customer-details-scroll {
    position: relative;
    height: calc(100vh - 120px);
    margin-top: 10px;
  }
  .device-count {
    display: flex;
    justify-content: space-between;
    @include responsiveProperty(
      font-size,
      $app_font_size_small,
      $app_font_size_1,
      $app_font_size_2
    );
    .total_count {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .customer-details-scroll-view {
    padding-right: 10px;
  }
  .customer-details-scroll-wrap {
    height: 100%;
    overflow-x: auto;
  }

  .users-devices-collapse > div > .el-collapse-item__header {
    font-weight: 600;
    color: #555;
  }

  .el-table--mini,
  .el-table--small,
  .el-table__expand-icon {
    font-size: 12px;
    height: calc(100vh - 261px) ;
    // @include responsiveProperty(height, 339px, 450px, 600px);
    overflow-y: scroll;
  }
}
</style>

<style lang="scss">
.customer-details-tabs {
  .small-table {
    height: calc(100vh - 190px) !important;
  }
  .small-table .sc-table .pagination-bar .pagination-eruvaka .el-pagination__sizes {
      margin-left: 5px;
      margin-top: -0.5rem !important;
      position: absolute;
      margin-left: -6rem !important;
  }
  .small-table .sc-table .pagination-bar .pagination-eruvaka .el-pagination__total {
    font-size: 13px;
    margin-top: 0.3rem !important;
    position: absolute;
    margin-left: 11rem !important;
  }
}
</style>
