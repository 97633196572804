<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: pmDetails.vue
Description:This file contains the  Schema Values of the pondmothers ( ex:Kg Dispensed Feed)
-->
<template>
  <div class="pm-details">
    <!-- <device-customer-details-table :customer-details="customerDetails" ></device-customer-details-table> -->
    <el-card>
      <template slot="header">
        <h4>PondMother Settings</h4>
      </template>
      <generate-form
        v-loading="saveSettingsLoading"
        :elFormOptions="elFormOptions"
        :schema="pmFields"
        :model="modelData"
        ref="generatedForm"
        :disabled="isDisabled"
        @model-change="handleModelChange"
      >
        <template slot="footer">
          <er-button
            btnType="save"
            :showLabel="true"
            :showIcon="true"
            size="mini"
            :disabled="isDisabled"
            @click="saveSettingsInDb"
          ></er-button>
        </template>
      </generate-form>
    </el-card>

    <el-card>
      <template slot="header">
        <h4>Latest sensor values</h4>
      </template>
      <single-device-settings-table
        :key="device._id"
        :settings-details="device.sensor_values"
        :keys="pmSensorKeys"
        :customer-details="customerDetails"
      ></single-device-settings-table>
    </el-card>
    <!-- <single-device-settings-table :settings-details="device.sensor_values"></single-device-settings-table> -->
  </div>
</template>

<script>
import PondMother from "@/model/pondmother";
import singleDeviceSettingsTable from "@/components/superadmin/shared/singleDeviceSettingsTable";
import { mapActions } from "vuex";
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
import generateForm from "@/components/superadmin/shared/generateForm";
export default {
  props: {
    customerDetails: {
      default: () => ({}),
    },
    deviceDetails: { default: () => ({}) },
    isDisabled: { default: true },
  },
  mixins: [errorHandlerMixin],
  components: {
    singleDeviceSettingsTable,
    generateForm,
  },
  data: function () {
    return {
      modelData: {},
      saveSettingsLoading: false,
    };
  },
  watch: {
    deviceDetails: function (newValue, oldValue) {
      this.initComponent();
    },
  },
  computed: {
    elFormOptions: function () {
      return {
        disabled: false,
        hideRequiredAsterisk: true,
        labelPosition: "top",
        layout: "grid",
        // inline: true
      };
    },
    device: function () {
      return this.deviceDetails || new PondMother();
    },
    pmFields() {
      return [
        {
          type: "input_number",
          inputType: "number",
          label: "Kg Dispsence Time (secs)",
          model: "kg_dispense_time",
          props: { precision: 0, min: 10, max: 35 },
          validationRules: "required",
        },
        {
          type: "input_number",
          inputType: "number",
          props: { disabled: true },
          label: "Doser Hall Status",
          model: "doser_hall_status",
          validationRules: "",
        },
        {
          type: "input_number",
          inputType: "number",
          label: "Data Interval (mins)",
          props: { precision: 0, min: 1 },
          model: "data_interval",
          validationRules: "required",
        },
        {
          type: "checkbox",
          inputType: "boolean",
          label: "Retain Previous Day Schedule",
          model: "retain_previous_day_schedule",
          validationRules: "",
        },
        {
          type: "checkbox",
          inputType: "boolean",
          label: "Disable Dummy Disp Feed",
          model: "actual_dispense_feed_enable",
          validationRules: "",
        },
        {
          type: "checkbox",
          inputType: "boolean",
          label: "Delete Future Schedules",
          model: "delete_future_schedules",
          validationRules: "",
        },
        {
          type: "checkbox",
          inputType: "boolean",
          label: "Midnight Schedule Enable",
          model: "midnight_sch_enable",
          validationRules: "",
        },
        {
          type: "input",
          props: { disabled: true },
          inputType: "text",
          label: "Software Version",
          model: "software_version",
          validationRules: "",
        },
        {
          type: "input",
          props: { disabled: true },
          inputType: "text",
          label: "Hardware Version",
          model: "hardware_version",
          validationRules: "",
        },
        {
          type: "checkbox",
          inputType: "boolean",
          label: "Device UI Edit",
          model: "device_ui_edit",
          validationRules: "required",
        },
        {
          type: "select",
          props: {
            options: [
              { mode: "SCHEDULE", label: "Schedule" },
              { mode: "BASIC", label: "Basic" },
              { mode: "AUTOMATIC", label: "Automatic" },
            ],
            disabled: true,
            valueKey: "mode",
            labelKey: "label",
          },
          inputType: "text",
          label: "Mode",
          model: "mode",
          validationRules: "",
        },
        {
          type: "input",
          props: { disabled: true },
          inputType: "text",
          label: "Mechanical Version",
          model: "mechanical_version",
          validationRules: "",
        },
        {
          type: "input",
          props: { disabled: true },
          inputType: "text",
          label: "Device Version",
          model: "device_version",
          validationRules: "",
        },
        {
          type: "input",
          props: { type: "textarea" },
          inputType: "text",
          label: "Group Settings",
          model: "group_settings",
          validationRules: { validFormat: { format: "JSON" } },
        },
        {
          type: "select",
          props: {
            options: [
              { label: "PM75", hopper_type: "PM75" },
              { label: "PM125", hopper_type: "PM125" },
              { label: "PM250 (S)", hopper_type: "PM250-S" },
              { label: "PM250 (C)", hopper_type: "PM250-C" },
              { label: "PM250 Max (S)", hopper_type: 'PM250-MAX-S' },
              { label: "PM250 Max (C)", hopper_type: 'PM250-MAX-C' }
            ],
            disabled: false,
            valueKey: "hopper_type",
            labelKey: "label",
          },
          inputType: "text",
          label: "Feeder Type",
          model: "hopper_type",
          validationRules: "",
        },
      ];
    },
    initPMSettings() {
      return {
        kg_dispense_time: 24,
        data_interval: 300,
        retain_previous_day_schedule: true,
        delete_future_schedules: false,
        midnight_sch_enable: false,
        software_version: "",
        hardware_version: "B007",
        device_version: "1.0.0",
        device_ui_edit: true,
        network: "LORA",
        doser_thresholdcurrent: 311,
        doser_hall_status: undefined,
        feed_gap: 60,
        mode: "BASIC",
        mechanical_version: "",
        group_settings: "{}",
        hopper_type: "PM125",
      };
    },
    pmSettingsKeys() {
      return {
        kg_dispense_time: "number",
        data_interval: "number",
        retain_previous_day_schedule: "boolean",
        delete_future_schedules: "boolean",
        midnight_sch_enable: "boolean",
        software_version: "string",
        hardware_version: "string",
        device_ui_edit: "boolean",
        mode: "string",
        mechanical_version: "string",
      };
    },
    pmSensorKeys() {
      return {
        date: "string",
        battery_voltage: "number",
        dispenser_impedance: "number",
        dispenser_current: "number",
        standard_deviation: "number",
        motor_status: "number",
        doser_current: "number",
        date_IST: "string",
        UTC_date: "string",
        doser_threshold_current: "number",
      };
    },
  },
  mounted() {
    this.initComponent();
  },
  methods: {
    ...mapActions("superadmin", {
      saveSingleDeviceSettingsData: "saveSingleDeviceSettingsData",
    }),
    initComponent() {
      this.modelData = this.$lodash.cloneDeep({
        ...this.initPMSettings,
        ...(this.device || { settings: {} }).settings,
        ...(this.device || { sensor_values: {} }).sensor_values,
      });
      this.$commonUtils.convertFieldValues(
        this.modelData,
        ["data_interval"],
        "secs",
        "mins"
      );
    },
    handleModelChange(value, path) {
      this.modelData[path] = value;
      this.modelData = Object.assign({}, this.modelData);
    },
    async saveSettingsInDb() {
      const response =
        await this.$refs.generatedForm.$refs.generatedFormValidator.validate();
      if (!response) {
        return;
      }
      await this.$confirm(
        "These changes will effect the behaviour of the device, Are you sure?",
        "Warning",
        {
          confirmButtonText: "Save",
          cancelButtonText: this.$t("Comn_cancel"),
          type: "warning",
        }
      );
      const payload = { ...this.modelData };
      this.$commonUtils.convertFieldValues(
        payload,
        ["data_interval"],
        "mins",
        "secs"
      );
      try {
        this.saveSettingsLoading = true;
        const response = await this.saveSingleDeviceSettingsData({
          device_code: this.device.code,
          payload,
          settingsType: "POND_MOTHER",
        });
        this.$notify({
          title: "Success",
          dangerouslyUseHTMLString: true,
          message: response.data.message,
          duration: 3000,
          type: "success",
        });
        await this.$emit("refresh", this.device._id);
      } catch (err) {
        this.$notify({
          title: "Error",
          dangerouslyUseHTMLString: true,
          message: "Error while saving the data",
          duration: 3000,
          type: "success",
        });
        this.ehm__errorMessages(err, true);
      }
      this.saveSettingsLoading = false;
    },
  },
};
</script>

<style lang="scss">
.settings-table-scroll {
  position: relative;
  height: max-content !important;
}
.gateway-details {
  margin-top: 10px;
  div.cell {
    text-transform: capitalize;
  }
}
</style>
